<template>
  <div class="student-drawer-header">
    <header class="student-drawer-header__main">
      <div class="student-drawer-header__main__id">
        <div class="student-drawer-header__main__id__name">
          <skeleton-loader
            v-if="loading"
            width="280px"
            height="38px"
          />
          <h3 v-else>
            {{ student.name }}
          </h3>
        </div>
        <div class="student-drawer-header__main__id__classroom">
          <skeleton-loader
            v-if="loading"
            width="220px"
            height="22px"
          />
          <p v-else>
            {{ classroomDetails }}
          </p>
        </div>
      </div>
      <div class="student-drawer-header__main__controls">
        <template v-if="!disableControls">
          <div
            v-if="mq_m__mf"
            class="student-drawer-header__main__controls__previous"
          >
            <s-button
              icon="arrow-left"
              variation="tertiary"
              :disabled="!hasPrevious"
              @click="$emit('previous-student')"
            />
          </div>
          <div
            v-if="mq_m__mf"
            class="student-drawer-header__main__controls__next"
          >
            <s-button
              icon="arrow-right"
              variation="tertiary"
              :disabled="!hasNext"
              @click="$emit('next-student')"
            />
          </div>
        </template>
        <div class="student-drawer-header__main__controls__close">
          <s-button
            icon="x"
            variation="tertiary"
            @click="$emit('close')"
          />
        </div>
      </div>
    </header>
    <div class="student-drawer-header__extra">
      <div class="student-drawer-header__extra__details">
        <div class="student-drawer-header__extra__details__lecture">
          <skeleton-loader
            v-if="loading"
            width="120px"
            height="25px"
          />
          <h6 v-else>
            {{ lecture }}
          </h6>
        </div>

        <div class="student-drawer-header__extra__details__chapter">
          <skeleton-loader
            v-if="loading"
            width="50%"
            height="27px"
          />
          <h5 v-else>
            {{ chapterDetails }}
          </h5>
          <s-badge
            v-if="!loading && student.status"
            :variation="getStatusVariation(student.status)"
          >
            {{ student.status }}
          </s-badge>
        </div>
      </div>
      <div class="student-drawer-header__extra__time-details">
        <div
          v-if="student.startedAt"
          class="student-drawer-header__extra__time-details__started-at"
        >
          <skeleton-loader
            v-if="loading"
            width="160px"
            height="22px"
          />
          <span v-else>
            {{
              $t('report.students.startedAt')
            }}
            {{ format(student.startedAt, 'dd/MM/yyyy - HH:mm') }}
          </span>
        </div>
        <div v-if="isShowAssignmentSelector">
          <skeleton-loader
            v-if="loading"
            width="240px"
            height="50px"
          />
          <AssignmentSelector
            v-else
            :assignments="assignments"
            :student="student"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { getStatusVariation } from 'App/utils/status'
import formatDate from 'App/mixins/formatDate'
import mediaQueries from 'App/mixins/mediaQueries'
import simplur from 'simplur'
import microfrontendService from '@/shared/utils/MicrofrontendService'
import translateShift from 'MFE/utils/shiftsTranslation'
import { isBookActivity } from './enums/questionnaireTypeEnum'
import AssignmentSelector from './AssignmentSelector'

export default {
  name: 'StudentDrawerHeader',
  components: {
    AssignmentSelector,
  },
  mixins: [ formatDate, mediaQueries ],
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    assignments: {
      type: Array,
      required: true,
    },
    disableControls: Boolean,
  },
  data() {
    return {
      isMicrofrontend: false,
    }
  },
  computed: {
    ...mapGetters([
      'classrooms',
      'activeClassroom',
    ]),
    ...mapState({
      isShowSessions: ({ featureToggles }) => featureToggles
        .toggles.isShowSessions,
    }),
    isShowAssignmentSelector() {
      return this.isShowSessions && this.isBookActivityMission
    },
    classroomDetails() {
      if (this.activeClassroom.ready) {
        const { grade, name, shift } = this.activeClassroom.data
        const isProductionMode = process.env.VUE_APP_MODE === 'production'
        const translateShiftText = translateShift(shift)

        const mfeShiftText = !isProductionMode
          ? this.$t(shift.charAt(0).toUpperCase() + shift.slice(1))
          : translateShiftText
        const sasShiftText = this.$t(`shifts.${shift}`)
        const shiftLabel = this.isMicrofrontend ? mfeShiftText : sasShiftText

        return `${grade.name} · ${name} · ${shiftLabel}`
      }

      return ''
    },
    lecture() {
      return this.questionnaire.data?.subtitle ?? ''
    },
    chapterDetails() {
      return this.questionnaire.data?.title ?? ''
    },
    isBookActivityMission() {
      return isBookActivity(this.questionnaire.data?.type)
    },
  },
  beforeMount() {
    this.isMicrofrontend = microfrontendService.get()
  },
  methods: {
    getStatusVariation,
    hitCount({ correctAnswers, questionsCount }) {
      return simplur`${correctAnswers}/${questionsCount} ${[ correctAnswers ]}[acerto|acertos]`
    },
  },
}
</script>

<style lang="scss" scoped>
.student-drawer-header {
  &__main {
    background: $color-white;
    box-shadow: $shadow-s rgba($color-ink, .15);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__id {
      padding: $size-s;

      @include mq-s--mf {
        padding: $size-l;
      }

      &__photo {
        margin-bottom: $size-s;
      }

      &__name {
        margin-bottom: $size-xxs;
      }

      &__classroom {
        color: $color-ink-light;
      }
    }

    &__controls {
      margin: $size-xs;
      @include flex-center;

      &__previous {
        padding-right: $size-xs;
      }

      &__next {
        border-right: 1px solid transparentize($color-ink-lightest, 0.5);
        padding-left: $size-xs;
        padding-right: $size-xs;
      }

      &__close {
        padding-left: $size-xs;
      }
    }
  }

  &__extra {
    padding: $size-s;
    display: flex;
    flex-wrap: wrap;

    @include mq-s--mf {
      padding: $size-m $size-l;
    }

    @include mq-m--mf {
      flex-wrap: nowrap;
    }

    &__details {
      flex-grow: 1;
      @include flex-center-start;
      flex-wrap: wrap;

      &__lecture {
        color: $color-ink-light;
        margin-right: $size-s;
      }

      &__chapter {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        margin: $size-xs 0 $size-s;

        h5 {
          margin-right: $size-xs;
        }
      }
    }

    &__time-details {
      flex-shrink: 1;
      white-space: nowrap;

      @include mq-m--mf {
        text-align: right;
      }

      &__started-at,
      &__finished-at {
        color: $color-ink-light;
        display: block;
      }

      &__started-at {
        margin-bottom: $size-xs;
      }

      ::v-deep .sas-skeleton-loader {
        display: inline-block;
      }
    }
  }
}
</style>
