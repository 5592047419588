<template>
  <div class="sas-activities">
    <wrapper>
      <AssignmentSectionLayout
        v-if="hasExtraActivities"
        class="sas-activities__extra-activities"
        :title="$t('commons.extraActivities')"
      >
        <ExtraActivities />
      </AssignmentSectionLayout>
      <section
        v-if="isAvailableGrades"
        class="sas-activities__eureka-banner"
      >
        <img
          :src="require('App/assets/eureka-banner.svg')"
          width="100%"
          alt="Eureka"
          @click="openEurekaInNewWindow"
        >
      </section>
      <section
        v-if="selectedLecture"
        class="sas-activities-filter"
      >
        <h4 class="sas-activities-filter__header">
          {{ $t('assignments.filterByLecture') }}
        </h4>
        <LecturePicker
          v-model="selectedLecture"
          :lectures="lectures"
          :loading="!selectedClassroom"
        />
      </section>
      <AssignmentSectionLayout
        v-if="hasProposedQuestionsReport"
        :title="$t('assignments.proposedActivities.section.title')"
      >
        <ProposedAssignment
          :proposed-assignment="proposedAssignment.data"
          :selected-lecture="selectedLecture"
          :selected-classroom="selectedClassroom"
        />
      </AssignmentSectionLayout>
      <AssignmentSectionLayout
        v-if="selectedClassroom"
        :title="$t('assignments.bookActivities')"
      >
        <EmptyState
          v-if="classroomHasNoLectures"
          title="Não existem atividades do livro para essa turma ou série"
          image="assignments-not-found"
        />
        <BookActivities
          v-else
          :grade-id="selectedClassroom.grade.id"
          :classroom-id="selectedClassroom.id"
          :selected-lecture="selectedLecture"
          :selected-classroom="selectedClassroom"
        />
      </AssignmentSectionLayout>
    </wrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import EmptyState from 'App/components/EmptyState'
import proposedAssignmentApi from '@/service/proposedAssignment'
import updateRoute from 'App/mixins/updateRoute'
import { urls } from 'shared/utils/urls'

import LecturePicker from 'App/components/LecturePicker'
import AssignmentSectionLayout from 'App/components/Assignments/AssignmentSectionLayout'

import ProposedAssignment from 'shared/views/Home/partials/ProposedAssignment'

import isEmpty from 'lodash/isEmpty'
import BookActivities from 'shared/views/Home/partials/BookActivities'
import ExtraActivities from 'shared/views/Home/partials/ExtraActivities'
import microfrontendService from '@/shared/utils/MicrofrontendService'

const ENUMS_FUNDAMENTAL_GRADES = [
  '4º ano',
  '5º ano',
  '6º ano',
  '7º ano',
  '8º ano',
  '9º ano',
]

export default {
  name: 'SasActivities',
  components: {
    EmptyState,
    BookActivities,
    ExtraActivities,
    ProposedAssignment,
    AssignmentSectionLayout,
    LecturePicker,
  },
  mixins: [ updateRoute ],
  props: {
    selectedClassroom: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedLecture: null,
      proposedAssignment: {
        data: null,
        loading: false,
        error: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'extraActivities',
      'user',
    ]),
    classroomHasNoLectures() {
      return isEmpty(this.selectedClassroom.lectures)
    },
    lectures() {
      return this.selectedClassroom?.lectures || []
    },
    lectureQueryParam() {
      return parseInt(this.$route.query.lectureId, 10) ?? this.selectedClassroom?.lectures[0]?.id
    },
    hasProposedQuestionsReport() {
      const { data, error } = this.proposedAssignment

      return !error && data?.hasReport
    },
    hasExtraActivities() {
      return this.extraActivities.ready
        && this.extraActivities.data.length
    },
    isAvailableGrades() {
      if (this.selectedClassroom) {
        const { name } = this.selectedClassroom.grade
        const { isSchool, isTeacher, isManager } = this.user
        const isAvailable = ENUMS_FUNDAMENTAL_GRADES.includes(name)

        return (isSchool || isTeacher || isManager) && isAvailable
      }

      return false
    },
  },
  watch: {
    lectures() {
      const { findLectureById, lectureQueryParam } = this
      const lectureFound = findLectureById(lectureQueryParam)
      const hasAvailableSelectedLecture = Boolean(lectureFound)

      if (hasAvailableSelectedLecture) {
        this.selectedLecture = lectureFound
      } else {
        const [ firstLecture ] = this.selectedClassroom.lectures

        this.selectedLecture = firstLecture
      }
    },
    selectedLecture() {
      const { selectedLecture } = this

      if (selectedLecture?.id) {
        this.updateRouteQuery('lectureId', selectedLecture.id)
        this.getProposedAssignmentInfo()
      }
    },
    selectedClassroom: {
      handler() {
        if (this.selectedClassroom) {
          this.getExtraActivities(this.selectedClassroom.grade.id)
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.lectureQueryParam) {
      this.selectedLecture = this.lectures.find(
        ({ id }) => id === this.lectureQueryParam
      )
    }
  },
  methods: {
    ...mapActions([
      'getExtraActivities',
    ]),
    openEurekaInNewWindow() {
      const gradeId = this.selectedClassroom?.grade?.id
      const { lectureId } = this.$route.query || {}

      if (!gradeId || !lectureId) {
        console.error('Missing required parameters', { gradeId, lectureId })

        return
      }

      const isMicrofrontend = microfrontendService.get()
      const redirectUrl = isMicrofrontend ? `${urls.CROSS_PORTAL_URL}/eureka` : urls.EUREKA_URI

      const url = `${redirectUrl}?gradeId=${encodeURIComponent(gradeId)}&lectureId=${encodeURIComponent(lectureId)}`

      window.open(url, '_blank')
    },
    findLectureById(lectureId) {
      return this.selectedClassroom.lectures.find((lecture) => lecture.id === lectureId)
    },
    async getProposedAssignmentInfo() {
      const { subject: { id: subjectId } } = this.selectedLecture

      this.proposedAssignment.loading = true

      try {
        const { data } = await proposedAssignmentApi.getProposedAssignmentInfo(
          subjectId,
          this.selectedClassroom.id
        )

        this.proposedAssignment.data = data
      } catch (error) {
        this.proposedAssignment.error = error
      } finally {
        this.proposedAssignment.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sas-activities {
  &__extra-activities {
    border-bottom: 1px solid transparentize($color-ink-lightest, 0.5);
    padding-bottom: $size-m;
    margin-bottom: $size-m;

    img {
      cursor: pointer;
    }
  }
}

.sas-activities-filter {
  @include flex-space-between;
  margin-top: $size-l;
  margin-bottom: $size-m;

  &__header {
    font-weight: $font_weight_medium;
    color: #666E75;
    line-height: 125%;
  }
}
</style>
