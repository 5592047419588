<template>
  <div class="recomposition-drawer-header">
    <header class="recomposition-drawer-header__main">
      <div class="recomposition-drawer-header__main__id">
        <div class="recomposition-drawer-header__main__id__name">
          <skeleton-loader
            v-if="loading"
            width="280px"
            height="38px"
          />
          <h3 v-else>
            {{ student.name }}
          </h3>
        </div>
        <div class="recomposition-drawer-header__main__id__classroom">
          <skeleton-loader
            v-if="loading"
            width="220px"
            height="22px"
          />
          <p v-else>
            {{ classroomDetails }}
          </p>
        </div>
      </div>
      <div class="recomposition-drawer-header__main__controls">
        <template v-if="!disableControls">
          <div
            v-if="mq_m__mf"
            class="recomposition-drawer-header__main__controls__previous"
          >
            <s-button
              icon="arrow-left"
              variation="tertiary"
              :disabled="!hasPrevious"
              @click="$emit('previous-student')"
            />
          </div>
          <div
            v-if="mq_m__mf"
            class="recomposition-drawer-header__main__controls__next"
          >
            <s-button
              icon="arrow-right"
              variation="tertiary"
              :disabled="!hasNext"
              @click="$emit('next-student')"
            />
          </div>
        </template>
        <div class="recomposition-drawer-header__main__controls__close">
          <s-button
            icon="x"
            variation="tertiary"
            @click="$emit('close')"
          />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatDate from 'App/mixins/formatDate'
import mediaQueries from 'App/mixins/mediaQueries'
import microfrontendService from '@/shared/utils/MicrofrontendService'
import translateShift from 'MFE/utils/shiftsTranslation'

export default {
  name: 'RecompositionDrawerHeader',
  mixins: [ formatDate, mediaQueries ],
  props: {
    student: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    disableControls: Boolean,
  },
  data() {
    return {
      isMicrofrontend: false,
    }
  },
  computed: {
    ...mapGetters([
      'classrooms',
      'activeClassroom',
    ]),
    classroomDetails() {
      if (this.activeClassroom.ready) {
        const { grade, name, shift } = this.activeClassroom.data

        const isProductionMode = process.env.VUE_APP_MODE === 'production'
        const translateShiftText = translateShift(shift)

        const mfeShiftText = !isProductionMode
          ? this.$t(shift.charAt(0).toUpperCase() + shift.slice(1))
          : translateShiftText
        const sasShiftText = this.$t(`shifts.${shift}`)
        const shiftLabel = this.isMicrofrontend ? mfeShiftText : sasShiftText

        return `${grade.name} · ${name} · ${shiftLabel}`
      }

      return ''
    },
  },
  beforeMount() {
    this.isMicrofrontend = microfrontendService.get()
  },
}
</script>

<style lang="scss" scoped>
.recomposition-drawer-header {
  &__main {
    background: $color-white;
    box-shadow: $shadow-s rgba($color-ink, .15);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__id {
      padding: $size-s;

      @include mq-s--mf {
        padding: $size-l;
      }

      &__name {
        margin-bottom: $size-xxs;
      }

      &__classroom {
        color: $color-ink-light;
      }
    }

    &__controls {
      margin: $size-xs;
      @include flex-center;

      &__previous {
        padding-right: $size-xs;
      }

      &__next {
        border-right: 1px solid transparentize($color-ink-lightest, 0.5);
        padding-left: $size-xs;
        padding-right: $size-xs;
      }

      &__close {
        padding-left: $size-xs;
      }
    }
  }
}
</style>
