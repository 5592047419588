<template>
  <div>
    <Header
      light-mode-on
      :title="title"
      :subtitle="subtitle"
      :navigation="goBackRoute"
      :loading="loading"
      :hide-body="students.error"
    >
      <template #right>
        <ClassPicker
          v-model="selectedClassroom"
          :classrooms="filteredClassrooms"
          :loading="classrooms.loading"
        />
      </template>
    </Header>
    <StudentsPerformanceView
      v-if="!error && (loading || hasStudents)"
      class="students-performance-view"
      :classroom-id="classroomId"
      :selected-classroom="selectedClassroom"
      :loading="loading"
      :students="students.data"
    />
    <wrapper v-else>
      <EmptyState
        v-if="!hasStudents"
        :image="noStudents.image"
        :title="noStudents.title"
        :description="noStudents.description"
      />
      <EmptyState
        v-else-if="students.error"
        :image="errorState.image"
        :title="errorState.title"
        :description="errorState.description"
      >
        <s-button
          icon-left="refresh-ccw"
          @click="fetchProposedAssignmentStudents"
        >
          {{ $t('commons.tryAgain') }}
        </s-button>
      </EmptyState>
    </wrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import microfrontendService from '@/shared/utils/MicrofrontendService'
import { isNotEmpty } from 'App/utils/arrays'
import proposedAssignmentApi from '@/service/proposedAssignment'
import updateRoute from 'App/mixins/updateRoute'
import EmptyState from 'App/components/EmptyState'
import ClassPicker from 'App/components/ClassPicker/ClassPicker'
import Header from 'App/components/Header/Header'
import StudentsPerformanceView from './partials/StudentsPerformanceView'

export default {
  name: 'StudentsPerformance',
  components: {
    ClassPicker,
    Header,
    EmptyState,
    StudentsPerformanceView,
  },
  mixins: [ updateRoute ],
  props: {
    classroomId: {
      type: Number,
      default: 0,
    },
    subjectId: {
      type: Number,
      default: 0,
    },
    lectureId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isMicrofrontend: false,
      selectedClassroom: null,
      students: {
        data: [],
        loading: false,
        error: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'classrooms',
      'yearSelectedContent',
    ]),
    subjectName() {
      if (!this.selectedClassroom) {
        return null
      }

      const classroomLectures = this.selectedClassroom.lectures

      return classroomLectures.find(({ id }) => id === this.lectureId)?.subject.name
    },
    goBackRoute() {
      return {
        label: this.$t('commons.back'),
        route: { name: this.isMicrofrontend ? 'mfe-sas-activities' : 'teacher-sas-activities' },
      }
    },
    filteredClassrooms() {
      if (!this.classrooms.ready || this.selectedClassroom === null) {
        return []
      }

      return this.classrooms.data.filter(
        ({ grade }) => grade.id === this.selectedClassroom.grade.id
      )
    },
    title() {
      return this.subjectName || ''
    },
    subtitle() {
      return this.$t('studentsPerformance.title')
    },
    loading() {
      return this.classrooms.loading
        || this.students.loading
    },
    error() {
      return this.classrooms.error
        || this.students.error
    },
    hasStudents() {
      return isNotEmpty(this.students.data)
    },
    errorState() {
      return {
        image: 'error',
        title: this.$t('report.classroomStatistics.error.title'),
        description: this.$t(
          'report.classroomStatistics.error.description'
        ),
      }
    },
    noStudents() {
      return {
        image: 'assignments-not-found',
        title: this.$t('report.classroomStatistics.emptyProposedAssignmentStudents.title'),
        description: this.$t('report.classroomStatistics.emptyProposedAssignmentStudents.description'),
      }
    },
  },
  watch: {
    selectedClassroom() {
      if (this.selectedClassroom?.id) {
        this.updateRouteParam('classroomId', this.selectedClassroom.id)
      }
      this.fetchProposedAssignmentStudents()
    },
  },
  mounted() {
    this.isMicrofrontend = microfrontendService.get()
  },
  async created() {
    if (!this.classrooms.ready) {
      await this.getClassrooms(this.yearSelectedContent)
    }
    this.setSelectedClassroom()
  },
  methods: {
    ...mapActions([
      'getClassrooms',
    ]),
    setSelectedClassroom() {
      if (this.classroomId) {
        this.selectedClassroom = this.classrooms.data.find(
          ({ id }) => id === this.classroomId
        )
      }
    },
    async fetchProposedAssignmentStudents() {
      const { subjectId, classroomId } = this

      this.students.loading = true

      try {
        const { data } = await proposedAssignmentApi.getProposedAssignmentStudents(
          subjectId,
          classroomId
        )
        this.students.data = data
      } catch (error) {
        this.students.error = error
      } finally {
        this.students.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.students-performance-view {
  margin: {
    top: $size_m;
    bottom: $size_m;
  }
}
</style>
