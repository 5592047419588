<template>
  <div
    :style="{ paddingBottom: isMFE ? '0' : '40vh' }"
  >
    <HomeHeader :subject-ids="subjectIds">
      <SchoolYearSelector @select-year="selectedYear" />
      <ClassPicker
        v-model="selectedClassroom"
        :classrooms="classrooms.data"
        :selected-classroom="selectedClassroom"
        :loading="classrooms.loading"
      />
    </HomeHeader>
    <router-view :selected-classroom="selectedClassroom" />
    <TeacherOnBoarding
      v-if="onBoarding"
      @teacherOnBoardingDone="teacherOnBoardingDone"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import updateRoute from 'App/mixins/updateRoute'
import storage from '@/service/storage'
import TeacherOnBoarding from 'App/components/TeacherOnBoarding'
import ClassPicker from 'App/components/ClassPicker/ClassPicker'
import SchoolYearSelector from 'App/components/SchoolYearSelector'
import microfrontendService from '@/shared/utils/MicrofrontendService'
import HomeHeader from './partials/HomeHeader'

export default {
  name: 'Home',
  components: {
    HomeHeader,
    TeacherOnBoarding,
    ClassPicker,
    SchoolYearSelector,
  },
  mixins: [ updateRoute ],
  data() {
    return {
      selectedClassroom: null,
      onBoarding: false,
      selectedLecture: null,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'classrooms',
      'yearSelectedContent',
    ]),
    isMFE() {
      return microfrontendService.get()
    },
    classroomQueryParam() {
      return parseInt(this.$route.query.classroomId, 10)
    },
    lectures() {
      return this.selectedClassroom?.lectures ?? []
    },
    lectureQueryParam() {
      return parseInt(this.$route.query.lectureId, 10) ?? this.selectedClassroom?.lectures[0]?.id
    },
    subjectIds() {
      return this.lectures.map((lecture) => lecture.subject.id)
    },
  },
  watch: {
    selectedClassroom() {
      if (this.selectedClassroom?.id) {
        this.updateRouteQuery('classroomId', this.selectedClassroom.id)
      }
    },
    classrooms() {
      if (this.classrooms.data) {
        if (this.classroomQueryParam) {
          this.setSelectedClassroom(this.classroomQueryParam)
        } else {
          const [ firstClassroom ] = this.classrooms.data

          this.selectedClassroom = firstClassroom
        }
      }
    },
    lectures() {
      this.setSelectLectures()
    },
    selectedLecture() {
      const { selectedLecture } = this

      if (selectedLecture?.id) {
        this.updateRouteQuery('lectureId', selectedLecture.id)
      }
    },
  },
  async created() {
    if (!this.classrooms.data) {
      await this.getClassrooms(this.yearSelectedContent)
    }

    if (!this.selectedClassroom && !!this.classroomQueryParam) {
      this.setSelectedClassroom(this.classroomQueryParam)
    }

    const onBoardedUsers = storage.getItem('@onboarding/Teacher')
    this.onBoarding = !(onBoardedUsers || []).includes(this.user.id)
  },
  methods: {
    ...mapActions([
      'getClassrooms',
      'setYearSelectedContent',
    ]),
    setSelectLectures() {
      const { findLectureById, lectureQueryParam } = this
      const lectureFound = findLectureById(lectureQueryParam)
      const hasAvailableSelectedLecture = Boolean(lectureFound)

      if (hasAvailableSelectedLecture) {
        this.selectedLecture = lectureFound
      } else {
        const [ firstLecture ] = this.selectedClassroom.lectures

        this.selectedLecture = firstLecture
      }
    },
    teacherOnBoardingDone() {
      const onBoardedUsers = storage.getItem('@onboarding/Teacher') || []
      storage.setItem('@onboarding/Teacher', [
        ...onBoardedUsers,
        this.user.id,
      ])
      this.onBoarding = false
    },
    async selectedYear(year) {
      const { yearSelectedContent } = this

      if (yearSelectedContent !== year) {
        this.setYearSelectedContent(year)

        await this.getClassrooms(year)
      }
    },
    setSelectedClassroom(classroomId) {
      if (classroomId) {
        this.selectedClassroom = this.classrooms.data.find(({ id }) => id === classroomId)
      }
    },
    findLectureById(lectureId) {
      return this.selectedClassroom.lectures.find((lecture) => lecture.id === lectureId)
    },
  },
}
</script>
