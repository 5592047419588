<template>
  <div class="logos-mission-report">
    <Header
      light-mode-on
      :title="chapterTitle"
      :subtitle="headingTopicName"
      :navigation="navigationRoute"
      :loading="loading"
    >
      <template #toolbar-right>
        <skeleton-loader
          v-if="loading && !error"
          opacity="1"
          height="22px"
          :width="mq_m ? '36px' : '105px'"
        />
        <template v-else>
          <s-button
            class="report-survey-button"
            variation="tertiary"
            icon-left="star"
            @click="openFeedbackSurvey"
          >
            O que você achou do relatório?
          </s-button>
        </template>
      </template>
      <template #right>
        <ClassPicker
          v-model="selectedClassroom"
          :classrooms="filteredClassrooms"
          :loading="loadingClassPicker"
        />
      </template>
    </Header>

    <wrapper class="logos-mission-report__container">
      <div class="logos-mission-report__cards">
        <RecompositionProgressionPathCard
          title="Progresso no diagnóstico"
          subtitle="% de alunos que finalizaram"
          :loading="studentsStatistics.loading"
          :donut-size="donutSize"
          :number="numberOfStudentsWhoFinished"
        />
        <RecompositionProgressionPathTestPerformance
          :loading="studentsStatistics.loading"
          :students-total-amount="studentsStatistics.data.studentsTotalAmount"
          :performance-range="studentsStatistics.data.performanceRange"
        />
      </div>
      <EssentialLearningEvaluatedTable
        ref="essentialLearningEvaluatedTable"
        :essential-learning-list="essentialLearningList.data"
        :loading="essentialLearningList.loading"
      />
      <RecompositionKnowledgeTestTable
        ref="knowledgeTestTable"
        :students="students.data"
        :loading="students.loading"
        :active-student="activeStudent"
        @select-student="selectStudent"
      />
      <RecompositionKnowledgeTestClassDetailsTableContainer
        :show-table="canShowTestTable"
        @set-questionnaire-code="onQuestionnaireCode"
        @track-event="trackQuestionEvent"
      />
    </wrapper>
    <RecompositionDrawer
      v-if="activeStudent"
      disable-controls
      :classroom-id="selectedClassroom.id"
      :recomposition-chapter="recompositionChapterInfo.data"
      :student="activeStudent"
      :diagnostic-status="activeStudent.diagnosticStatus"
      :has-previous="hasPreviousStudent"
      :has-next="hasNextStudent"
      :questionnaire-code="questionnaireCode"
      @close="clearSelectedStudent"
      @previous-student="previousStudent"
      @next-student="nextStudent"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isNil, isEmpty } from 'lodash'

import { urls } from 'shared/utils/urls'
import logosApi from '@/service/logos'
import mediaQueries from 'App/mixins/mediaQueries'
import updateRoute from 'App/mixins/updateRoute'
import typeform from '@/service/typeform'

import Header from 'App/components/Header/Header'
import ClassPicker from 'App/components/ClassPicker/ClassPicker'

import microfrontendService from '@/shared/utils/MicrofrontendService'
import RecompositionDrawer from './components/RecompositionDrawer/RecompositionDrawer'
import RecompositionProgressionPathCard from './components/RecompositionProgressionPathCard/RecompositionProgressionPathCard'
import RecompositionProgressionPathTestPerformance from './components/RecompositionProgressionPathTestPerformance/RecompositionProgressionPathTestPerformance'
import RecompositionKnowledgeTestTable from './components/RecompositionKnowledgeTestTable/RecompositionKnowledgeTestTable'
import RecompositionKnowledgeTestClassDetailsTableContainer from './components/RecompositionKnowledgeTestClassDetailsTableContainer/RecompositionKnowledgeTestClassDetailsTableContainer'
import EssentialLearningEvaluatedTable from './components/EssentialLearningEvaluatedTable/EssentialLearningEvaluatedTable'

const questionsTypeEnum = {
  RECOMPOSITION_DIAGNOSTIC: 'RECOMPOSITION_DIAGNOSTIC',
  RECOMPOSITION_RECOVERY: 'RECOMPOSITION_RECOVERY',
  RECOMPOSITION_RECOVERY_RESULT: 'RECOMPOSITION_RECOVERY_RESULT',
}

export default {
  name: 'LogosMissionReport',
  components: {
    Header,
    ClassPicker,
    RecompositionDrawer,
    RecompositionProgressionPathCard,
    RecompositionKnowledgeTestTable,
    EssentialLearningEvaluatedTable,
    RecompositionProgressionPathTestPerformance,
    RecompositionKnowledgeTestClassDetailsTableContainer,
  },
  mixins: [ mediaQueries, updateRoute ],
  props: {
  },
  data() {
    return {
      isMicrofrontend: false,
      studentsStatistics: {
        loading: false,
        error: null,
        data: [],
      },
      recompositionChapterInfo: {
        loading: false,
        error: null,
        data: [],
      },
      essentialLearningList: {
        loading: false,
        error: null,
        data: [],
      },
      students: {
        loading: false,
        error: null,
        data: [],
      },
      questionnaireCode: null,
      selectedClassroom: null,
      loadingClassPicker: true,
      activeStudentInnerIndex: 0,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'classrooms',
      'yearSelectedContent',
    ]),
    canShowTestTable() {
      return !this.loading && !isEmpty(this.students.data) && this.numberOfStudentsWhoFinished > 0
    },
    loading() {
      return !this.classrooms.ready
        || this.studentsStatistics.loading || this.recompositionChapterInfo.loading
    },
    numberOfStudentsWhoFinished() {
      return this.studentsStatistics?.data?.diagnostic?.finished
    },
    numberOfAveragePerformance() {
      return this.studentsStatistics?.data?.diagnostic?.averagePerformance
    },
    donutSize() {
      return this.mq_xs ? 88 : 116
    },
    recompositionChapterId() {
      return this.$route.params.recompositionChapterId
    },
    classroomQueryParam() {
      return parseInt(this.$route.query.classroomId, 10)
    },
    error() {
      return this.classrooms.error
        || this.studentsStatistics.error || this.recompositionChapterInfo.error
    },
    filteredClassrooms() {
      if (!this.classrooms.ready || isNil(this.selectedClassroom)) {
        return []
      }

      return this.classrooms.data.filter(
        ({ grade }) => grade.id === this.selectedClassroom.grade.id
      )
    },
    classroomPathParam() {
      return parseInt(this.$route.params.classroomId, 10)
    },
    headingTopicName() {
      const { headingTopicName, chapterNumber } = this.recompositionChapterInfo.data

      return `${headingTopicName} · Capítulo ${chapterNumber}`
    },
    chapterTitle() {
      return this.recompositionChapterInfo.data.chapterTitle ?? ''
    },
    navigationRoute() {
      return {
        label: 'Atividades por Capítulo',
        route: {
          name: this.isMicrofrontend ? 'mfe-logos-report' : 'teacher-logos-report',
          query: {
            classroomId: this.classroomPathParam,
            lectureId: this.$route.query.lectureId,
          },
        },
      }
    },
    activeStudent() {
      const { activeStudent } = this.$route.query
      if (!activeStudent) return null
      const [ student ] = this.students.data.filter((item) => (
        item.student.id === Number.parseInt(activeStudent, 10)
      ))

      const { id, name } = student.student
      const { recovery, diagnosticStatus } = student
      const { status, able, recommended } = recovery

      return {
        id,
        name,
        status,
        able,
        diagnosticStatus,
        recommended,
      }
    },
    activeStudentIndex() {
      return this.students.data.findIndex((student) => (
        student.student.id === this.activeStudent.id
      ))
    },
    hasPreviousStudent() {
      return this.activeStudentInnerIndex > 0
    },
    hasNextStudent() {
      return this.activeStudentInnerIndex < this.students.data.length - 1
    },
  },
  watch: {
    selectedClassroom(newSelectedClassroom, oldSelectedClassroom) {
      this.updateRouteParam('classroomId', this.selectedClassroom?.id)
      const isNotInitialState = oldSelectedClassroom !== null

      if (newSelectedClassroom !== oldSelectedClassroom && isNotInitialState) {
        this.refresh()
      }
    },
  },
  destroyed() {
    this.updateRouteQueryWithReplace('tab', null)
  },
  created() {
    this.startFetches()
  },
  mounted() {
    this.isMicrofrontend = microfrontendService.get()
  },
  methods: {
    ...mapActions([ 'getClassrooms' ]),
    refresh() {
      this.startFetches()
    },
    async fetchClassrooms() {
      try {
        if (!this.classrooms.ready) {
          await this.getClassrooms(this.yearSelectedContent)
        }

        if (this.classroomPathParam) {
          this.selectedClassroom = this.classrooms.data.find(
            ({ id }) => id === this.classroomPathParam
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingClassPicker = false
      }
    },
    async fetchRecompositionChapterInfo() {
      this.recompositionChapterInfo.loading = true
      try {
        const { data } = await logosApi.getStudentsRecompositionChapterData(
          this.recompositionChapterId,
          this.yearSelectedContent,
        )
        this.recompositionChapterInfo.data = data
        this.recompositionChapterInfo.error = false
      } catch (error) {
        this.recompositionChapterInfo.error = true
      } finally {
        this.recompositionChapterInfo.loading = false
      }
    },
    async fetchProgressionPathDiagnosticData() {
      this.studentsStatistics.loading = true
      try {
        const { data } = await logosApi.getProgressionPathDiagnosticData({
          recompositionChapterId: this.recompositionChapterId,
          classroomId: this.classroomPathParam,
          contentYear: this.yearSelectedContent,
        })
        this.studentsStatistics.data = data
        this.studentsStatistics.error = false
      } catch (error) {
        this.studentsStatistics.error = true
      } finally {
        this.studentsStatistics.loading = false
      }
    },
    async fetchStudentsPerformanceData() {
      this.students.loading = true
      try {
        const { data } = await logosApi.getStudentsPerformanceData({
          recompositionChapterId: this.recompositionChapterId,
          classroomId: this.classroomPathParam,
          contentYear: this.yearSelectedContent,
        })
        this.students.data = data
        this.students.error = false
      } catch (error) {
        this.students.error = true
      } finally {
        this.students.loading = false
      }
    },
    async fetchEssentialLearningList() {
      this.essentialLearningList.loading = true
      try {
        const { data } = await logosApi.getEssentialLearningList({
          recompositionChapterId: this.recompositionChapterId,
          classroomId: this.classroomPathParam,
          contentYear: this.yearSelectedContent,
        })

        this.essentialLearningList.data = data
        this.essentialLearningList.error = false
      } catch (error) {
        console.error('error', error)
        this.essentialLearningList.error = true
      } finally {
        this.essentialLearningList.loading = false
      }
    },
    async startFetches() {
      await Promise.all([
        this.fetchClassrooms(),
        this.fetchRecompositionChapterInfo(),
        this.fetchProgressionPathDiagnosticData(),
        this.fetchStudentsPerformanceData(),
        this.fetchEssentialLearningList(),
      ])
    },
    isDiagnosticTitle(type) {
      return type === questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC
    },
    trackQuestionEvent(info) {
      const label = {
        questionId: info.id,
        studentsHitRatePercentage: info.hitRate,
        essentialLearning: this.isDiagnosticTitle(info.type) ? 'Missão Lógos' : info.essentialLearningTitle,
      }

      this.$trackEvent({
        category: this.$track.category.logosReport,
        action: this.$track.action.seeLogosQuestionDetails,
        label,
      })
    },
    openFeedbackSurvey() {
      const appBaseUrl = urls.BASE_URL
      const accountId = this.user.id
      const platform = 'web'
      const url = appBaseUrl.concat(this.$route.path)
      const [ profile ] = this.user.profiles

      const formId = 'n1q8tApP'

      const typeformPopup = typeform.createPopup({
        formId,
        hiddenFields: {
          account_id: accountId,
          platform,
          url,
          profile: profile.name,
          chapter_id: this.recompositionChapterId,
        },
      })

      this.$trackEvent({
        category: this.$track.category.feedback,
        action: this.$track.action.rateExperience,
      })

      typeformPopup.open()
    },
    onQuestionnaireCode(code) {
      this.questionnaireCode = code
    },
    clearSelectedStudent() {
      const query = { ...this.$route.query }
      delete query.activeStudent
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })
    },
    selectStudent({ data, index }) {
      const { id } = data
      const activeStudent = Number.parseInt(this.$route.query.activeStudent, 10)
      const { assignmentCode } = data

      if (id !== activeStudent) {
        this.activeStudentInnerIndex = index
        this.$router.push({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: {
            ...this.$route.query,
            activeStudent: id,
            assignmentCode,
          },
        })
      }
    },
    previousStudent() {
      if (this.mq_m) {
        if (this.activeStudentIndex > 0) {
          this.selectStudent({
            data: this.filteredStudents[this.activeStudentIndex - 1],
            dataIndex: this.activeStudentIndex - 1,
          })
        }
      } else {
        this.$refs.knowledgeTestTable.previousStudent()
      }
    },
    nextStudent() {
      if (this.mq_m) {
        if (this.activeStudentIndex < this.filteredStudents.length - 1) {
          this.selectStudent({
            data: this.filteredStudents[this.activeStudentIndex + 1],
            dataIndex: this.activeStudentIndex + 1,
          })
        }
      } else {
        this.$refs.knowledgeTestTable.nextStudent()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.logos-mission-report {
  padding-bottom: 200px;

  &__container {
    padding: $size-l 0;
  }

  &__cards {
    display: flex;
    gap: $size-s;
  }
}

</style>
