import httpCodes from 'App/enums/httpCodes'
import loginAndReturn from 'App/utils/loginAndReturn'
import cookies from '@/service/cookies'
import storage from '@/service/storage'
import { getRouter } from 'shared/router/RouterService'
import microfrontendService from '@/shared/utils/MicrofrontendService'

const accessTokenCookieName = 'accessToken'
const tokenCookieName = 'token'

export const getAuthToken = () => cookies.getItem(tokenCookieName)
export const getAccessToken = () => sessionStorage.getItem(accessTokenCookieName)

export function addAuthorizationHeader(config) {
  const requestConfig = { ...config }

  requestConfig.headers.Authorization = getAuthToken()

  return requestConfig
}

function shouldRedirectToErrorPage(error, config) {
  const isActivitiesReport = storage.getItem('domain') === 'activities-report'
  const is400Error = error.response.status === httpCodes.badRequest
  const is404Error = error.response.status === httpCodes.notFound
  const is422Error = error.response.status === httpCodes.unprocessableEntity
  const is403Error = error.response.status === httpCodes.forbidden

  const isStatisticsEndpoint = /statistics$/.test(config.url)
  const isEnterAssignmentEndpoint = /\/questionnaires\/.+\/(assignments|questions)$/.test(config.url)
  const isContinueAssignmentEndpoint = /\/assignments\/(.+\/(answers|questions\/.+)|\d+)$/.test(config.url)
  const isReportEndpoint = /\/questionnaires\/.+\/reports$/.test(config.url)
  const isProposedAssignmentInfoEndpoint = /classrooms\/.+\/subjects\/.+\/proposed-assignment-info$/.test(config.url)

  if (
    isStatisticsEndpoint
    || is400Error
    || is422Error
    || (isProposedAssignmentInfoEndpoint && is403Error)
  ) {
    return false
  }

  if (isActivitiesReport) {
    if (
      (isEnterAssignmentEndpoint && is404Error)
      || (isContinueAssignmentEndpoint && is422Error)
      || (isReportEndpoint && is422Error)
    ) {
      return false
    }
  }

  return true
}

export function addXCrossTokenHeader(config, token = getAccessToken()) {
  const requestConfig = { ...config }

  requestConfig.headers['x-cross-token'] = token

  return requestConfig
}

export async function handleResponseInterceptorError(error) {
  if (!error.response && error.message === 'Network Error') {
    return Promise.reject(new Error({
      ...error,
      response: { status: httpCodes.notFound },
    }))
  }

  const { status, config } = error.response
  const is401Error = status === httpCodes.unauthorized

  if (is401Error) {
    cookies.removeItem('token')
    loginAndReturn()
  } else if (shouldRedirectToErrorPage(error, config)) {
    const router = getRouter()
    const isMicrofrontend = microfrontendService.get()
    router.push({ name: isMicrofrontend ? 'mfe-error' : 'error', params: { status } })
  }

  return Promise.reject(error)
}
