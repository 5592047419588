import axios from 'axios'

import { addAuthorizationHeader, handleResponseInterceptorError, addXCrossTokenHeader } from 'App/utils/auth'
import { urls } from 'shared/utils/urls'
import microfrontendService from '@/shared/utils/MicrofrontendService'

const isMicrofrontend = microfrontendService.get()

const api = axios.create({
  baseURL: urls.ASSIGNMENTS_API,
  timeout: process.env.VUE_APP_TIMEOUT,
})

api.interceptors.request.use(addAuthorizationHeader)

if (isMicrofrontend && (process.env.VUE_APP_MODE !== 'production')) {
  api.interceptors.request.use(addXCrossTokenHeader)
}
api.interceptors.response.use((response) => response, handleResponseInterceptorError)

export default api
